import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Stores.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME, MASTER_SHOWROOM_REGION} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";

class Stores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showroomRegions: null
        }
    }

    componentDidMount() {
        document.getElementById("loader-wrapper").style = "display: flex";
        document.getElementById("loader").style = "display: block";

        apiCall(
            MASTER_SHOWROOM_REGION + '?store_listing=1',
            'get',
            null,
            res => {
                this.setState({
                    showroomRegions: res.data.showroom_region_list
                }, () => {
                    document.getElementById("loader-wrapper").style = "display: none";
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                // this.hideLoader();
            }
        );
        // setTimeout(function () {
        //     document.getElementById("loader-wrapper").style = "display: none";
        // }, 2000);
    }

    renderRegions = (showroomRegions) => {
        return showroomRegions
            .filter(showroomRegion => !empty(showroomRegion?.showrooms))
            .map((showroomRegion, index) => {
                return (
                    <div key={index} className={styles.region}>
                        <div className={styles.regionTitle}>
                            {showroomRegion.region_name}
                        </div>
                        <div className={styles.showrooms}>
                            {this.renderShowrooms(showroomRegion?.showrooms)}
                        </div>
                    </div>
                );
            });
    }

    renderShowrooms = (showrooms) => {
        return showrooms.map((showroom, index) => {
            return (
                <div key={index} className={styles.showroom}>
                    <div className={styles.showroomName}>
                        {showroom.showroom_name}
                    </div>
                    <div className={styles.showroomContact}>
                        {showroom.phone}
                    </div>
                    <div className={styles.showroomAddress}>
                        {showroom.address1}<br/>
                        {showroom.address2}<br/>
                        {showroom.address3}
                    </div>
                    {!empty(showroom.maps_url) && (
                        <div className={styles.mapsBtn}>
                            <a href={showroom.maps_url} target="_blank" rel="noopener noreferrer">View on Google Maps</a>
                        </div>
                    )}
                </div>
            );
        });
    }

    render() {
        const {showroomRegions} = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Inspired by an Island in Rio de Janeiro, Brazil. COCONUT ISLAND rapidly developing into a premium brand t-shirt in Indonesia."/>
                </Helmet>

                <section className={styles.coverSection}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/banner-stores-v2.jpeg`} />
                </section>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>Stores</h1>
                    <p className={styles.subtitle}>Established in 2007, Coconut island brand inspired by an island Rio de Janeiro, Brazil. Coconut Island rapidly develop into a premium brand t-shirt in Indonesia</p>
                </section>

                <section className={styles.shippingInfo}>
                    <h2 className={styles.title}>Two Ways of Online Shopping</h2>
                    <div className={styles.icons}>
                        <div className={styles.iconItem}>
                            <Link to={`stores/online-delivery`}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-onlinedelivery.png`} alt="Online Delivery" />
                                <div className={styles.iconTitle}>Online Delivery</div>
                                <div className={styles.iconSubtitle}>from your nearest shop</div>
                            </Link>
                        </div>
                        <div className={styles.iconItem}>
                            <Link to={`stores/pickup-on-store`}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-pickuponstore.png`} alt="Store Pickup" />
                                <div className={styles.iconTitle}>Store Pickup</div>
                                <div className={styles.iconSubtitle}>for quicker order</div>
                            </Link>
                        </div>
                    </div>
                </section>

                <section className={`container ${styles.showroomSection}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            {empty(showroomRegions) && "Loading.."}

                            {!empty(showroomRegions) && this.renderRegions(showroomRegions)}
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />
            </React.Fragment>
        );
    }
}

export default Stores;